// configs
@import './configs/config';

// universal components
@import './components/buttons';
@import './components/universal';

// sections
@import './components/header';
@import './components/promo';
@import './components/footer';

// desktop promo/billboard
@import './components/coins';
@import './components/billboard';

// popups
@import './components/popup';

@import './components/popup-projects';

@import './components/popup-form';

// cap 
@import './components/cap.scss'
